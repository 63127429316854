import React from 'react'
import { withRouter } from 'react-router-dom'
import { Header, Grid, Container } from 'semantic-ui-react'
import LogoutButton from '../../navigation/LogoutButton'
import ExportSquadronReportForm from '../../forms/export/export.squadron.report.form'
import { NAV_ROUTES } from '../../navigation/routes'
import AHSidebar from '../../components/Navigation/NavSidebar'

const ExportPage = () => {
  return (
    <>
      <AHSidebar currentRoute={ NAV_ROUTES.EXPORT_SQUADRON_REPORT_PAGE } />
      <Container>
        <Header as='h1'>
          <Grid>
            <Grid.Row columns='equal'>
              <Grid.Column data-testid='export-squadron-report-header'>Export Squadron Report</Grid.Column>
              <Grid.Column textAlign='right'>
                <LogoutButton />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Header>
        <ExportSquadronReportForm />
      </Container>
    </>
  )
}

ExportPage.propTypes = {
}

export default withRouter(ExportPage)
