import { takeLatest, put, call } from 'redux-saga/effects'
import afreService from '../../api/afre.service'
import AsyncSaga from './util/AsyncSaga'
import { reportActions } from '../constants/report.constants'
import { downloadFile } from './util/fileUtils'

export const getRecruiterReportExport = new AsyncSaga(reportActions.GET_RECRUITER_REPORT_EXPORT, function* getRecruiterReportExportSaga(action) {
  const response = yield call(afreService.getRecruiterReportExport, { ...action.params })
  yield put(getRecruiterReportExport.success())
  const {
    data,
    headers: {
      'content-type': contentType,
      'content-disposition': contentDisposition,
    },
  } = response
  yield call(downloadFile, data, contentType, contentDisposition.match(/[A-Za-z0-9_:.-]+\.\w+/u)[0])
})

export const getSquadronReportExport = new AsyncSaga(reportActions.GET_SQUADRON_REPORT_EXPORT, function* getSquadronReportExportSaga(action) {
  const response = yield call(afreService.getSquadronReportExport, { ...action.params })
  yield put(getSquadronReportExport.success())
  const {
    data,
    headers: {
      'content-type': contentType,
      'content-disposition': contentDisposition,
    },
  } = response
  yield call(downloadFile, data, contentType, contentDisposition.match(/[A-Za-z0-9_:.-]+\.\w+/u)[0])
})

export default [
  takeLatest(getRecruiterReportExport.REQUEST, getRecruiterReportExport.saga),
  takeLatest(getSquadronReportExport.REQUEST, getSquadronReportExport.saga),
]
