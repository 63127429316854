import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@emotion/react'
import { Dimmer, Loader, Grid } from 'semantic-ui-react'

import { Header } from '../share/share.page.styles'
import { fetchRecruiter, fetchRecruiterExperienceData, fetchRecruiterInformation } from '../../redux/sagas/user.sagas'
import {
  selectFetchRecruiterErrors,
  selectIsLoadingRecruiterExperienceData,
  selectRecruiterExperienceData,
  selectRecruiterInformation,
  selectSingleUser
} from '../../redux/selectors/user.selectors'

import afreCmsService from '../../api/afre.cms.service'
import ErrorPage from '../../components/ErrorPage/ErrorPage'
import { DarkPalette } from '../../theme'
import Intro from './Intro'
import Footer from './Footer'
import LeadForm from './LeadForm'
import ComponentLogo from '../../components/ComponentLogo/ComponentLogo'
import DetailsAccordion from './DetailsAccordion'
import styled from '@emotion/styled'
import spacing from '../../theme/spacing'
import About from './About'
import { getRankByRecruiterExperience } from './rankUtil'
import { AimHighButton } from '../../components/Button/Button'
import { NAV_ROUTES } from '../../navigation/routes'
import { logPageView } from '../../redux/sagas/amplitudeAnalytics.sagas'

export const Page = styled.div`
  background: ${({ theme }) => theme.background.level0};
  min-height: 100vh;
  padding: ${props => props.theme.spacing.m}px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
`

ProfilePage.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
}

function ProfilePage(props) {
  const dispatch = useDispatch()

  const user = useSelector(selectSingleUser)
  const avatarUrl = getUserAvatarUrl()
  const recruiterExperience = useSelector(selectRecruiterExperienceData)
  const isLoadingRecruiterExperienceData = useSelector(selectIsLoadingRecruiterExperienceData)
  const recruiterInformation = useSelector(selectRecruiterInformation)
  const fetchRecruiterErrors = useSelector(selectFetchRecruiterErrors)
  const fullName = user ? `${user.firstName} ${user.lastName}` : ''
  const mobileDetailsGridRef = useRef(null)
  const rank = getRankByRecruiterExperience(recruiterExperience, user)
  const params = new URLSearchParams(props.location.search)
  const leadId = params.get('leadId')
  const afrissEventId = params.get('eventId')

  function getUserAvatarUrl() {
    if (!!user && !!user.avatar) {
      const avatarParts = user.avatar.split('/')
      return afreCmsService.getUserAvatarUrl(avatarParts[0], avatarParts[1])
    }
  }
  useEffect(() => {
    const { userId } = props.match.params
    dispatch(fetchRecruiter.request({ id: userId }))
    dispatch(fetchRecruiterExperienceData.request({ id: userId }))
    dispatch(logPageView.request(NAV_ROUTES.PROFILE_PAGE))
  }, [dispatch, props.match.params])

  useEffect(() => {
    if (user && user.email) {
      dispatch(fetchRecruiterInformation.request())
    }
  }, [dispatch, user])

  function renderProfileFooter() {
    return <Footer />
  }

  function renderLeadForm() {
    return <LeadForm recruiterId={ user?.id } leadId={ leadId } afrissEventId={ afrissEventId } />
  }
  function renderAbout() {
    return !!user && !!user.websiteOverview &&
      <About websiteOverview={ user.websiteOverview } title={ `${rank} ${user.lastName}` } />
  }

  function renderDetailsGrid() {
    if (!user) {
      return
    }
    return (
      <>
        <Grid.Column style={ { paddingBottom: spacing.m } }>{ renderAbout() }</Grid.Column>
        <DetailsAccordion
          palette={ DarkPalette }
          user={ user }
          recruiterExperience={ recruiterExperience }
          recruiterInformation={ recruiterInformation }
        />
      </>
    )
  }

  const errorMessages = [{
    statusCode: 404,
    message: 'The specified user could not be found',
  }]

  const getIntro = isMobile => (
    <Intro
      isLoadingRecruiterExperienceData={ isLoadingRecruiterExperienceData }
      avatarUrl={ avatarUrl }
      palette={ DarkPalette }
      rank={ rank }
      name={ fullName }
      isMobile={ isMobile }
    />
  )
  const numberOfColumns = user ? 2 : 1

  return fetchRecruiterErrors ?
    <ErrorPage error={ fetchRecruiterErrors } errorMessages={ errorMessages } /> :
    <ThemeProvider theme={ DarkPalette }>
      <Helmet>
        <meta property='og:url' content={ window.location.href } />
        <meta property='og:type' content='image' />
        <meta property='og:title' content='' />
        <meta property='og:description' content='' />
      </Helmet>
      <Dimmer.Dimmable dimmed={ isLoadingRecruiterExperienceData }>
        <Dimmer active={ isLoadingRecruiterExperienceData }>
          <Loader>Loading</Loader>
        </Dimmer>
        <Page>
          <Header style={ { padding: spacing.m, paddingBottom: spacing.l, justifyContent: 'center' } }>
            <ComponentLogo recruiterComponent={ user?.recruiterComponent } />
          </Header>
          <Grid stackable columns={ numberOfColumns } style={ { maxWidth: '1000px', justifyContent: 'center' } }>
            { user ?
              <Grid.Column width={ 10 }>
                <Grid columns={ 1 }>
                  <Grid.Column only='computer tablet' style={ { paddingBottom: 0 } }>
                    { getIntro(false) }
                  </Grid.Column>
                  <Grid.Column only='mobile' style={ { paddingBottom: 0 } }>
                    { getIntro(true) }
                  </Grid.Column>
                  <Grid.Row centered only='mobile' style={ { paddingTop: 0 } }>
                    <AimHighButton
                      onClick={ () => mobileDetailsGridRef.current.scrollIntoView({ behavior: 'smooth' }) }
                      basic inverted icon='chevron circle down'
                      content='Check Out My Bio'
                    />
                  </Grid.Row>
                  <Grid.Column only='tablet computer'>{ renderDetailsGrid() }</Grid.Column>
                </Grid>
              </Grid.Column> : <></> }
            <Grid.Column width={ 12 / numberOfColumns }>
              { renderLeadForm() }
            </Grid.Column>
            { user ?
              <Grid.Column only='mobile'>
                <div ref={ mobileDetailsGridRef } />
                { renderDetailsGrid() }
              </Grid.Column> : <></> }
          </Grid>
          { renderProfileFooter() }
        </Page>
      </Dimmer.Dimmable>
    </ThemeProvider>
}

export default ProfilePage
