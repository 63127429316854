import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Field, getFormSyncErrors, hasSubmitFailed, reduxForm } from 'redux-form'
import { Button, Dropdown } from 'semantic-ui-react'
import FieldLabel from '../../components/Forms/FieldLabel'
import { getSquadronReportExport } from '../../redux/sagas/report.sagas'
import { fetchSquadrons } from '../../redux/sagas/squadron.sagas'
import { selectGetReportExportError, selectSquadronExportIsLoading } from '../../redux/selectors/report.selectors'
import { selectSquadrons } from '../../redux/selectors/squadron.selectors'
import { LargeFieldLabel } from '../form.styles'
import { required } from '../validation'
import { ServerError } from '../ValidationError'
import { ExportFormItem, ExportFormSubmitContainer, ExportInput } from './export.form.styles'

class ExportSquadronReportForm extends Component {

  componentDidMount() {
    this.props.fetchSquadrons()
  }

  SquadronSelector = ({ name, label, squadrons, ...props }) => {
    const formattedSquadrons = squadrons.map(s => ({ key: s.id, text: s.name, value: s.id }))
    return (
      <>
        <LargeFieldLabel data-testid='squadron-label' htmlFor={ name }>{ label }</LargeFieldLabel>
        <ExportInput>
          <Dropdown
            selection
            search
            fluid
            placeholder='Squadron'
            data-testid='squadron-dropdown'
            options={ formattedSquadrons }
            value={ props.input.value }
            onChange={ (_e, squadron) => props.input.onChange(squadron.value) }
          />
        </ExportInput>
      </>
    )
  }

  handleExportSubmit = values => {
    this.props.submitExport(values)
  }

  validateExport = errors => {
    const items = []
    Object.values(errors).forEach(error => {
      items.push(<li key={ error }>{ error === 'Required' ? `Squadron Required` : error }</li>)
    })
    return (<FieldLabel data-testid='field-label-error' hasError>{ items }</FieldLabel>)
  }

  render() {
    const { handleSubmit, submitFailed, error, formSyncErrors, squadrons, squadronExportIsLoading } = this.props
    return (
      <form onSubmit={ handleSubmit(this.handleExportSubmit) }>
        <ExportFormItem>
          <Field
            id='squadron'
            name='squadron'
            label='Squadron'
            props={ { squadrons } }
            component={ this.SquadronSelector }
            validate={ required }
          />
        </ExportFormItem>
        <ExportFormSubmitContainer>
          <Button type='submit' disabled={ squadronExportIsLoading } loading={ squadronExportIsLoading } data-testid='export-button'>Export</Button>
          { (formSyncErrors && submitFailed) && this.validateExport(formSyncErrors) }
          { error && <ServerError error={ error } /> }
        </ExportFormSubmitContainer>
      </form>
    )
  }
}

ExportSquadronReportForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitExport: PropTypes.func,
  submitFailed: PropTypes.bool,
  squadronExportIsLoading: PropTypes.bool,
  formSyncErrors: PropTypes.any,
  error: PropTypes.any,
  fetchSquadrons: PropTypes.func,
  squadrons: PropTypes.array,
}

const mapStateToProps = state => {
  return {
    submitFailed: hasSubmitFailed('exportSquadronReport')(state),
    formSyncErrors: getFormSyncErrors('exportSquadronReport')(state),
    error: selectGetReportExportError(state),
    squadrons: selectSquadrons(state),
    squadronExportIsLoading: selectSquadronExportIsLoading(state),
  }
}

const mapActionsToProps = dispatch => {
  return {
    submitExport: params => dispatch(getSquadronReportExport.request(params)),
    fetchSquadrons: () => dispatch(fetchSquadrons.request()),
  }
}

const Formed = reduxForm({
  form: 'exportSquadronReport',
  enableReinitialize: true,
})(ExportSquadronReportForm)
const ConnectedExportSquadronReportForm = connect(mapStateToProps, mapActionsToProps)(Formed)

export default withRouter(ConnectedExportSquadronReportForm)
