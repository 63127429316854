import styled from '@emotion/styled'

export const ExportFormSubmitContainer = styled.div`
  flex: 1;
  align-items: start;
  margin: 10px 0px 10px 0px;
`

export const ExportInput = styled.div`
  border-radius: 5px;
  width: 25%;
`

export const ExportFormItem = styled.div`
  margin: 10px 0px 10px 0px;
`
